import React from 'react'
import SearchForm from "./SearchForm";
import { ReactComponent as Logo } from './logo.svg'
import { ReactComponent as WordMark } from './wordMark.svg'

export const Header = () =>{
    return (
<div className='d-flex flex-row align-items-center mx-4 gap-3'>
<Logo/>
<WordMark /> 
<div>
<SearchForm />
</div>
</div>
    )
}


/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useRouteLoaderData } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { ProductResult } from "../components/productTemplate";
// import {

//   Paging
// } from "@elastic/react-search-ui";

import "../css/styles.css";
import "../css/index.css";

import "@elastic/react-search-ui-views/lib/styles/styles.css";

export const ProductList = () => {
  const data = useRouteLoaderData("allParts");
  const fusionQueryId = data.responseHeader.params.fusionQueryId;

  const numShowing = data.response.numFound <= data.responseHeader.params.rows ?data.response.numFound : data.responseHeader.params.rows 

  function DisplayList({ products }) {
    console.log(products);
    return products.map((product) => {
      console.log(product);
      if (product["materialMaster.productNumberDisplay"]) {
        return <ProductResult result={product} requestId={fusionQueryId} />;
      } else {
        return null;
      }
    });
  }

  return (

    <Container fluid className="App">
      <>
        <div className="d-flex flex-column  justify-content-center align-items-center">
          <h1>Showing {numShowing} of {data.response.numFound} Parts</h1>
          <div className="d-flex flex-column w-50">
            <DisplayList products={data.response.docs} />
          </div>
        </div>
        {/* <Paging /> */}
      </>
    </Container>

  );
};

/** Properties */

export const Connection = {
  user: "search_svc",
  password: "NTEyZDE5NWI3OTE1",
  serverUrl: "https://molex-dev.b.lucidworks.cloud",
  app: "molex_neo",
  queryProfileProductDetails: "product-details",
  queryProfileWebSearch: "main",
  queryProfileSeriesList: "series-list",
  queryProfileSeriesParts: "parts-per-series",
  queryProfileXref:"xref",
  queryProfileCategory:"products-per-category",
  signalsEndpoint: "/api/signals/molex_neo",
  //suggestionsProfile:"suggestions"
  suggestionsProfile:"gja-suggestions"
};

export const ResultProperties = {
  sorting: {},
  facetFields:{},
  autocompleteQuery: {
    suggestions: {
      types: {
        parts: {
          fields: ["suggestion"],
        },
    
      product: {
        fields: ["suggestion"],
      }
  },
      size: 5,
    },
  },
};

export const UIProperties = {
  alwaysSearchOnInitialLoad: true,
  debug: false,
  hasA11yNotifications: true,
  placeholderText:
    "Search our large range of electronic connectors and more...",
  autoCompleteSuggestionTitle: "Suggestions",
  autocompleteMinimumCharacters: 3,
  autocompleteSuggestions:true,
  showFacets: true,
  showResultsPerPage: true,
  showSorting: false,
  showLeftCol: true,
  showTags: false,
  content_facets:[ "content_types",
    "industry",],
  product_facets: [
 "taxonomyPathValueLast",
 "materialMaster.productSeries.seriesName",
 "compliance.reachStatus",
 "materialMaster.productManagerGlobal",
 "materialMaster.promotable",
 "materialMaster.productStatus",
 "materialMaster.productFamilyCode",
 "materialMaster.labOfficeName",
 "compliance.roHsStatus",
 "general.productName",
 "materialMaster.productLine",
 "materialMaster.salesStatusCode",
 "electrical.currentMaximumPerContact",
 "electrical.dataRate",
 "electrical.dataSpeed",
 "electrical.frequency",
 "electrical.gfci",
 "electrical.impedance",
 "electrical.insertionLossIlMaxDB",
 "electrical.lumens",
 "electrical.onOffSwitch",
 "electrical.polarity",
 "electrical.shielded",
 "electrical.voltage",
 "electrical.voltageMaximum",
 "general.application",
 "general.cableGlandType",
 "general.capillarySeries",
 "general.componentType",
 "general.connectorToConnector",
 "general.contactType",
 "general.enclosureRating",
 "general.fiberSeries",
 "general.function",
 "general.ipRating",
 "general.jedecOutline",
 "general.kitOption",
 "general.levelOfAutomation",
 "general.market",
 "general.mode",
 "general.nemaConfiguration",
 "general.performanceCategory",
 "general.powerOverEthernetPoE",
 "general.productCategory",
 "general.protocol",
 "general.region",
 "general.standard",
 "general.style",
 "general.toolType",
 "general.type",
 "materialMaster.productSeries.seriesId",
 "physical.accessoryType",
 "physical.actuatorType",
 "physical.angledPhysicalContactApc",
 "physical.approximateAwgEquivalent",
 "physical.approximateBreakStrength",
 "physical.baleLength",
 "physical.barrelType",
 "physical.bodyStyle",
 "physical.bootColor",
 "physical.bootType",
 "physical.braidType",
 "physical.bufferDiameter",
 "physical.cableDiameter",
 "physical.cableLength",
 "physical.cableSize",
 "physical.cardDetectionSwitch",
 "physical.circuitsLoaded",
 "physical.circuitsMaximum",
 "physical.cladDiameter",
 "physical.colorCableJacket",
 "physical.colorResin",
 "physical.componentSize",
 "physical.connection",
 "physical.connectorEndA",
 "physical.connectorEndB",
"physical.contactLayoutType",
"physical.contactPosition",
"physical.cordGripBodySize",
"physical.cordLength",
"physical.cordLengthPrimary",
"physical.coreDiameter",
"physical.couplingType",
"physical.depth",
"physical.diameter",
"physical.direction",
"physical.drumLock",
"physical.durabilityMatingCyclesMax",
"physical.duty",
"physical.edgeCardThickness",
"physical.entryAngle",
"physical.entryLocation",
"physical.ferruleHoleSize",
"physical.fiberCoreCladding",
"physical.flammability",
"physical.gender",
"physical.glandColor",
"physical.glassCharacteristic",
"physical.glowWireCapable",
"physical.guardType",
"physical.guideToMatingPart",
"physical.homeRunConnector",
"physical.homeRunInterface",
"physical.housingColor",
"physical.insulated",
"physical.insulation",
"physical.internalDiameter",
"physical.jackHeight",
"physical.keyway",
"physical.lampType",
"physical.latchColor",
"physical.ledIndicator",
"physical.length",
"physical.lightedConnector",
"physical.matedHeight",
"physical.matedWidth",
"physical.materialBody",
"physical.materialCoating",
"physical.materialOuterJacket",
"physical.materialPlatingMating",
"physical.meshLength",
"physical.mountingStyle",
"physical.mountingThreadSize",
"physical.numberOfButtons",
"physical.numberOfColumns",
"physical.numberOfConductors",
"physical.numberOfPairs",
"physical.numberOfRows",
"physical.numberOfUsers",
"physical.operatingDistance",
"physical.orientation",
"physical.output",
"physical.outsideDimensions",
"physical.packagingType",
"physical.panelMount",
"physical.pcbMounting",
"physical.pcTailLength",
"physical.pitchMatingInterface",
"physical.platingMinMating",
"physical.poles",
"physical.portConfiguration",
"physical.ports",
"physical.positionsLoadedContacts",
"physical.powerInput",
"physical.powerSignalConfiguration",
"physical.rearLegs",
"physical.receptacleType",
"physical.sensorHousingSize",
"physical.shutter",
"physical.slot",
"physical.studSize",
"physical.tabThickness",
"physical.tabWidth",
"physical.temperatureRangeOperating",
"physical.terminationInterfaceStyle",
"physical.thermalHeight",
"physical.thermalSolutions",
"physical.threadSize",
"physical.travelDistance",
"physical.waterproofDustproof",
"physical.wavelengthRange",
"physical.weightRange",
"physical.wireCableType",
"physical.wireSizeAwg",
"physical.wireSizeMm"
  ],
};

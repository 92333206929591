import React from "react";
import { ContentResult } from "./contentTemplate";
import { ProductResult } from "./productTemplate";

export const ResultTemplate = ({
  rawResults,
  results,
  showSnippet = false,
  showDescription = true,
  urlField = "url",
  snippetField = "body_content",
  titleField = "title",
  descriptionField = "description",
  thumbnailField = "thumbnail",
  requestId = "1",
  resultSearchTerm = "",
  pageType = "content",
}: {
  rawResults: any;
  results: any[];
  showSnippet?: boolean;
  showDescription?: boolean;
  urlField: string;
  snippetField: string;
  titleField: string;
  descriptionField?: string;
  requestId: string;
  resultSearchTerm: string;
  pageType: string;
  thumbnailField: string;
}) => {
  // console.log(pageType);
  // console.log(requestId);

   //console.log(pageType, results)



  if (!results) return <div data-testid="results" />;
  return (
    (pageType === "product" || pageType === "products-per-category"?
      results.map((result, idx) => {
        return (
            <React.Fragment key={pageType + idx}>
              <ProductResult
                result={result}
                index={idx}
                requestId={requestId}
                resultSearchTerm={resultSearchTerm}
              />
            </React.Fragment>
        );
      })
      :
      results.map((result, idx) => {
        return (

          <React.Fragment key={pageType + idx}>
            <ContentResult
              result={result}
              index={idx}
              requestId={requestId}
              resultSearchTerm={resultSearchTerm}
            />
          </React.Fragment>

        );
      })

    )
  )
}

import { SearchBox } from "@elastic/react-search-ui";
import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { UIProperties } from "../config/properties";
import { ReactComponent as Search } from "./searchIcon.svg";
//import { highlight } from "./HighLightString";

function SearchForm({ shouldClearFilters = true }) {
 
  return (
    <SearchBox
      autocompleteMinimumCharacters={UIProperties.autocompleteMinimumCharacters}
      //autocompleteSuggestions={UIProperties.autocompleteSuggestions}
      shouldClearFilters={shouldClearFilters}
      className="search-box"
      debounceLength={100}
      autocompleteSuggestions={{
        // Types used here need to match types requested from the server
        content: {
          sectionTitle: "Suggestions",
        },
        product: {
          sectionTitle: "Find in Part Catalogue",
        },
        parts: {
          sectionTitle: "Part or Series Number",
        },
        spelling:{
          sectionTitle: "Did you mean"
        }
      }}
      onSelectAutocomplete={(selection, { completeSuggestion }) => {
        if (selection) {
          completeSuggestion(selection);
        }
      }}
      autocompleteView={({
        autocompletedSuggestions,
        autocompleteSuggestions,
        getItemProps,
        getInputProps,
        setSearchTerm,
        getMenuProps,
      }) => (
        <div
          {...getMenuProps({
            className:
              "sui-search-box__autocomplete-container autocomplete-container",
          })}
        >
          {Object.keys(autocompletedSuggestions).map((category) => {
           //  console.log(autocompletedSuggestions);
          //   console.log(autocompletedSuggestions[category]);
            if (autocompletedSuggestions[category].length > 0) {
              return (
                <>
                  <p className={`section-title ${category} material`}>
                    {autocompleteSuggestions[category].sectionTitle}
                  </p>
                  <ul className={`sui-search-box__suggestion-list ${category}`}>
                    {autocompletedSuggestions[category].map((suggestion, i) => {
                    //  console.log(category , suggestion);
                    //  console.log(suggestion.suggestion.suggest);
                      return (
                        <li
                          {...getItemProps({
                            item: suggestion.suggestion.suggest,
                            key: i,
                          })}
                          id={`downshift-0-item-${i}`}
                          key={suggestion.suggestion.suggest}
                        >
                          {suggestion.suggestion.suggest}
                        </li>
                      );
                    })}
                  </ul>
                </>
              );
            } else {
              return null;
            }
          })}
        </div>
      )}
      inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
        <div className="sui-search-box__wrapper flex-row">
          <input
            {...getInputProps({
              className: "search-input",
              placeholder: UIProperties.placeholderText,
            })}
          />
          <Button
            {...getButtonProps({
              className: "search-btn",
            })}
          >
            <Search width={16} height={16} />
          </Button>
          {getAutocomplete()}
        </div>
      )}
    />
  );
}
export default SearchForm;

SearchForm.propTypes = {
  shouldClearFilters: PropTypes.bool,
  autocompleteSuggestions: PropTypes.bool,
};

import { useState, useEffect, createContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./css/styles.css";
import "./css/index.css";
import {
  Route,
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  useRouteError,
  isRouteErrorResponse
} from "react-router-dom";
import { ProductDetails } from "./pages/ProductDetails";
import { SeriesList } from "./pages/SeriesList";
import { Home } from "./pages/Home";
import { Search } from "./pages/Search";
import { ProductList } from "./pages/ProductList"; 
import { Xref } from "./pages/XRef"
import axios from "axios";
import { Connection } from "./config/properties";

export const Context = createContext(null);

const App = () => {
  const [ip, setIP] = useState(null);
  const credentials = window.btoa(`${Connection.user}:${Connection.password}`);

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    if (ip === null) {
      getData();
    }
    //passing getData method to the lifecycle method
  }, [ip]);

  console.log(ip);

  function ErrorBoundary() {
    const error = useRouteError();
    console.log(error)
    if (isRouteErrorResponse(error)) {
      return (
        <div>
          <h1>Oops!</h1>
          <h2>{error.status}</h2>
          <p>{error.statusText}</p>
          {error.data?.message && <p>{error.data.message}</p>}
        </div>
      );
    } else {
      return <div>Oops</div>;
    }
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          id="allParts"
          path="products"
          element={<ProductList />}
          // errorElement={<ErrorBoundary />}
          loader={async  () => {
            
           
            const params = new URLSearchParams(window.location.search)
            const rows = params.get('rows') || 10;
            const start = params.get('start') ||0;
            return fetch(
              `${Connection.serverUrl}/api/apps/${Connection.app}/query/part-list?rows=${rows}&start=${start}`,
              {
                headers: { Authorization: `Basic ${credentials}` },
              }
            );
          }}
        />
        <Route 
         id="productDetails"
        path="products/:id" element={<ProductDetails key={"product"}/>} 
          loader={async ({ params }) => {
            return fetch(
              `${Connection.serverUrl}/api/apps/${Connection.app}/query/${Connection.queryProfileProductDetails}?q=${params.id}`,
              {
                headers: { Authorization: `Basic ${credentials}` },
              }
            );
          }}
      />
        <Route
          id="series"
          path="/series/"
          element={<SeriesList />}
          loader={async () => {
            return fetch(
              `${Connection.serverUrl}/api/apps/${Connection.app}/query/series-list`,
              {
                headers: { Authorization: `Basic ${credentials}` },
              }
            );
          }}
          errorElement={<ErrorBoundary />}
        />{" "}
        <Route
          id="partsPerSeries"
          path="/series/search"
          errorElement={<ErrorBoundary />}
          element={<Search profileType={"parts-per-series"} />}

        />
         <Route
          id="mms-543-demo"
          path="/mms-543"
          errorElement={<ErrorBoundary />}
          element={<Search profileType={"mms-543"} />}

        />
        <Route path="search" element={<Search profileType={"main"} />} />
        <Route path="xref" element={<Xref  />} />
         <Route
          path="categories/search"
          id="parts-per-category"
          errorElement={<ErrorBoundary />}
          element={<Search profileType={"products-per-category"} />}
        />
        <Route path="/" element={<Home />} id="root" />
      </>
    )
  );

  return (
    <Container fluid className="App">
      <Row>
        <Col>
          {ip === null ? (
            <Row>
              <Col>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "25vh" }}
                >
                  <Spinner animation="border" variant="secondary" />
                </div>
              </Col>
            </Row>
          ) : (
            <Context.Provider value={ip}>
              <RouterProvider router={router} />
            </Context.Provider>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default App;

/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useContext }  from "react";
import { Link, useRouteLoaderData } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { TrackSearch } from "../components/SignalGeneration";
import { Context } from "../App"

export const SeriesList = () => {
  const data = useRouteLoaderData("series").grouped;
  const requestId = useRouteLoaderData("series").responseHeader.params.fusionQueryId;
  console.log(requestId)
  const ip = useContext(Context);
  let clickData = {
    type: "click",
    params: {
      query: "*:*",
      user_id: "search_svc",
      ctype: "result",
      fusion_query_id: requestId,   
      ip_address:  ip,
      browser_name:  window.navigator.userAgent
    }
  };

  function DisplayList({ data }) {
    const listItems = data.map((item) => {
        if (item.groupValue !== undefined){
            return <li key={item.groupValue.toString()}><Link to={`/series/search?q=${item.groupValue}`} onClick={() => {
              clickData.params["url"]=`/series/search?q=${item.groupValue}`
              TrackSearch(clickData);
            }}>{item.groupValue}</Link></li>;
        }
        else{
            return null
        }
    
    });
    return <ul >{listItems}</ul>;
  }

  return (
    <>
      <Container fluid className="App">
        <>
          <div className="d-flex flex-column  justify-content-center align-items-center m-3">
            <h1>Series</h1>
            <ul className="eight-col">
              <DisplayList data={data["materialMaster.productSeries.seriesId"].groups}  />
            </ul>
          </div>
        </>
      </Container>
    </>
  );
};

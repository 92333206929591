import React, { useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import { FusionAPIConnector } from "../components/FusionApiConnector";
import {
  ErrorBoundary,
  SearchProvider,
  PagingInfo,
  Paging,
  WithSearch,
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import { Header } from "../components/Header";
import { Context } from "../App";
import "../css/styles.css";
import "../css/index.css";
import { Connection } from "../config/properties";

function TableInfo({ data }) {
  return (
    <tr>
      <td> {data.competitorpn}</td>
      <td>{data.xrefstatus}</td>
      <td>{data.competitor}</td>
      <td> {data.competitorpndesc}</td>
      <td>{data.productnumber}</td>
      <td>{data.level}</td>
    </tr>
  );
}

const ResultTemplate = ({ results }) => {
  return results.map((result, idx) => <TableInfo data={result} key={idx} />);
};

export const Xref = () => {
  const ip = useContext(Context);
  //console.log(ip);
  const connector = new FusionAPIConnector({
    queryProfile: Connection.queryProfileXref,
    serverUrl: Connection.serverUrl,
    app: Connection.app,
    ip: ip,
  });

  const config = {
    apiConnector: connector,
    debug: true,
    hasA11yNotifications: true,
    autocompleteSuggestions: true,
  };

  //   function FacetList() {
  //     return UIProperties.facets.map((facet, index) => (
  //       <Facet
  //         key={index}
  //         className={"sui-facet__list"}
  //         field={facet}
  //         label={facet
  //           .replace(/(_[a-z]{1,2})$/, "")
  //           .replace(/_/, " ")
  //           .replace(/([A-Z])/g, " $1")
  //           .replace(/^./, function (str) {
  //             return str.toUpperCase();
  //           })}
  //         filterType="any"
  //       />
  //     ));
  //   }

  return (
    <SearchProvider config={config}>
      <WithSearch
        mapContextToProps={({
          wasSearched,
          results,
          resultSearchTerm,
          isLoading,
          requestId,
          rawResponse,
          facets,
        }) => ({
          wasSearched,
          results,
          resultSearchTerm,
          isLoading,
          requestId,
          rawResponse,
          facets,
        })}
      >
        {({
          wasSearched,
          results,
          resultSearchTerm,
          isLoading,
          requestId,
          rawResponse,
          facets,
        }) => {
          return (
            <div className="App" id="xref">
              <ErrorBoundary>
                <Container fluid className="App">
                  <Row className="header">
                    <Col>
                      <Header />
                    </Col>
                  </Row>
                  {isLoading ? (
                    <Row>
                      <Col>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "25vh" }}
                        >
                          <Spinner animation="border" variant="secondary" />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col>
                        <Layout
                          bodyContent={
                            wasSearched && (
                              <table className="table table-striped  table-bordered">
                                <thead className="table-light">
                                  <tr>
                                    <th scope="col">Competitor Part</th>
                                    <th scope="col">Cross Status</th>
                                    <th scope="col">Manufacturer</th>
                                    <th scope="col">Details</th>
                                    <th scope="col">Molex Part</th>
                                    <th scope="col">Level</th>
                                  </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                  <ResultTemplate
                                    results={results}
                                    urlField="id"
                                    titleField="title"
                                    snippetField="description"
                                    showSnippet={false}
                                    ip_address={ip}
                                    requestId={requestId}
                                    resultSearchTerm={resultSearchTerm}
                                  />
                                </tbody>
                              </table>
                            )
                          }
                          bodyHeader={
                            <React.Fragment>
                              {wasSearched && <PagingInfo className="mb-3" />}
                            </React.Fragment>
                          }
                          bodyFooter={<Paging />}
                        />
                      </Col>
                    </Row>
                  )}
                </Container>
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
};

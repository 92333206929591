import React,{ useContext } from "react";
import parse from "html-react-parser";
import { TrackSearch } from "./SignalGeneration";
import { Context } from "../App"

export const ContentResult =({
    result,
    index = 1,
    showSnippet = false,
    showDescription = true,
    urlField = "id",
    snippetField = "body_content",
    titleField = "title",
    descriptionField = "description",
    thumbnailField = "thumbnail",
    requestId = "1",
    resultSearchTerm = "",
  }: {
    result: any[];
    index: number;
    showSnippet?: boolean;
    showDescription?: boolean;
    urlField?: string;
    snippetField?: string;
    titleField?: string;
    descriptionField?: string;
    requestId: string;
    resultSearchTerm: string;
    thumbnailField?: string;
    
  }) =>{

    const ip = useContext(Context);
  //  console.log(ip)

     let clickData = {
      type: "click",
      params: {
        user_id: "search_svc",
        ctype: "result",
        query: resultSearchTerm,
        fusion_query_id: requestId,
        res_pos: index,
        doc_id: result["id"],
        url: result["id"],
        ip_address:  ip,
        browser_name:  window.navigator.userAgent
      }
    }
    


return(
<div key={result["id"]} className="individualResult" role="article">
{result[thumbnailField] && result[thumbnailField] !== "" ? (
  <img src={result[thumbnailField]} alt="" className="thumb img-thumbnail" />
) : (
  <img
    src="https://via.placeholder.com/200"
    alt="placeholder"
    className="thumb img-thumbnail"
  />
)}
<div>
  <h4>
    {result[urlField]  && result[titleField].length===1 ? (
      <a href={result[urlField]}  onClick={(e) => {
        e.preventDefault();
        console.log(clickData)
        TrackSearch(clickData);
        window.location.href=result[urlField]
      }}>
        {parse(result[titleField][0])}
      </a>
    ) :  (
      <a href={result[urlField]}  onClick={(e) => {
        e.preventDefault();
        console.log(clickData)
        TrackSearch(clickData);
       window.location.href=result[urlField]

      }}>
        {result[urlField]}
      </a>
    )}
  </h4>
  <p className="url">{result[urlField]}</p>
  {/* {showSnippet && result[snippetField] ? (
    <p className="snippet">
      {parse(result[snippetField][0])}{" "}
    </p>
  ) : (
    ""
  )} */}

  {showDescription && result[descriptionField]? (
   <p className="snippet">
   {parse(result[descriptionField][0])}{" "}
 </p>
  ) : (
    ""
  )}
</div>
</div>
)}
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { useRouteLoaderData } from "react-router-dom";
import { formatCamelCase } from "../components/formatCamelCase";

const ProductAttributeArray = ({ data }) => {
  var array = data;

  return array.map((item) => {
    // console.log(typeof item)
    if (typeof item === "string") {
      return item;
    } else {
      return (
        <table className="table table-striped table-bordered w-100">
          <tbody>
            <ProductAttributes data={item}/>
          </tbody>
        </table>
      );
    }
  });
};

const ProductAttributes = ({ data }) => {
  if (!data) {
    return;
  }
  return Object.entries(data).map(([key, value]) => {
    if (!Array.isArray(value) && typeof value === "object" && value === null) {
      return null;
    } else if (Array.isArray(value) && value.length === 0) {
      return null;
    } else if (
      typeof value === "string" &&
      (value === "null" || value === "" || value === null)
    ) {
      return null;
    }
    // check if it is an array with that isn't zero length
    else if (Array.isArray(value) && value.length > 0) {
      return (
        <tr>
          
          <td style={{ width: "30%" }}>{formatCamelCase(key)}</td>
          <td>
            <ProductAttributeArray data={value} key={key} />
          </td>
        </tr>
      );
    } else {
      return (
        <>
          <tr>
            <td style={{ width: "30%" }}>{formatCamelCase(key)}</td>
            <td>
              {typeof value === "object" ? (
                <table className="table table-striped table-bordered w-100">
                  <tbody>
                    <ProductAttributes data={value} key={key} />
                  </tbody>
                </table>
              ) : (
                value.toString()
              )}
            </td>
          </tr>
        </>
      );
    }
  });
};

const ProductData = ({ fields }) => {
  if (!fields) {
    return;
  }
  return Object.entries(fields).map(([key, value]) => {
    if (key.indexOf("_") !== -1 || key === "id") {
      return null;
    } else {
      return (
        <>
          <h3>{formatCamelCase(key)}</h3>
          <table className="table table-striped table-bordered w-100">
            <tbody>
              <ProductAttributes data={value} key={key} />
            </tbody>
          </table>
        </>
      );
    }
  });
};
export const ProductDetails = () => {
  const data = useRouteLoaderData("productDetails");
  console.log(data);
  return(
    <div className="container-fluid">
    <div className="row">
    
  {
   data.products.map((product) => {
    console.log(data.products.length);

    return (
      <>
        <div className="col">
        <div className={` ${data.products.length===1?"d-flex flex-column align-items-start mx-4 mt-5 gap-3": "d-flex flex-column align-items-stretch p-3 mt-3 border"}`}>
          <>
            <div className={` ${data.products.length===1?"d-flex flex-row justify-content-between align-items-center w-100": "d-flex flex-row justify-content-between align-items-center w-100 mb-3 flex-wrap"}`}>
              <div className={` ${data.products.length===1?"flex-fill px-5": "flex-fill"}`}>
                <ul className="list-unstyled">
                  {product.general.productCategory && (
                    <li className="category">
                      {product.general.productCategory}
                    </li>
                  )}

                  <li>
                    <h1>{product.materialMaster.productDescription}</h1>
                  </li>
                  <li className="part-number">
                    {product.materialMaster.productNumberDisplay}
                  </li>
                  <li>{product.general.webDescription}</li>
                </ul>
              </div>
              <div >
                {product.digitalAssets?.isoImage &&
                product.digitalAssets.isoImage !== "null" ? (
                  <img src={product.digitalAssets.isoImage} alt="" />
                ) : (
                  <img 
                    src={`https://via.placeholder.com/${Math.floor(610/data.products.length)}x${Math.floor(400/data.products.length)}`}
                    alt="placeholder"

                  />
                )}
              </div>
            </div>
            <div className="d-flex flex-row gap-5  w-100 ">
              <div className="flex-fill">
                <h2>Part Details</h2>
                <ProductData fields={product} key={product.materialMaster.productNumberDisplay} />
              </div>
            </div>
          </>
        </div>
        </div>
      </>
    );
  })
}
</div>
</div>

  )
};

import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CardGroup from "react-bootstrap/CardGroup";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
//import axios from "axios";

export const Home = () => {
  const navigate = useNavigate();

  return (
    <>
    <Container>      <Row>
        <Col>
          <h1 className="p-3">
            Available pages:{" "}
            <small className="text-muted">
              {" "}
              to demonstrate the available endpoints
            </small>
          </h1>
        </Col>
      </Row>

      <Row>
        <CardGroup className="mb-3">
          <Card>
            <Card.Body>
              <Card.Title as="h3"> Product Details</Card.Title>
              <Card.Subtitle as="h4" className="muted">
                {" "}
                Endpoint #1: Part Details for a Given Part Number{" "}
              </Card.Subtitle>
              <Card.Text>
                API
                https://molex-dev.b.lucidworks.cloud/api/apps/molex_neo/query/product-details?q=1731120600
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              {" "}
              <Button
                variant="primary"
                onClick={() => navigate("/products/1731120600")}
              >
                Part #1731120600
              </Button>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title as="h3">Main Search</Card.Title>
              <Card.Subtitle as="h4" className="muted">
                {" "}
                Endpoint #2: main intent-test or 
              </Card.Subtitle>
              <Card.Text>
                API
                https://molex-dev.b.lucidworks.cloud/api/apps/molex_neo/query/main?q=wire
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              {" "}
              <Button
                variant="primary"
                onClick={() => navigate("search?q=wire")}
              >
                Search for wire
              </Button>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title as="h3">MMS 543 Demo</Card.Title>
              <Card.Subtitle as="h4" className="muted">
                {" "}
                Demo default facets based on initial results
              </Card.Subtitle>
              <Card.Text>
                API
                https://molex-dev.b.lucidworks.cloud/api/apps/molex_neo/query/mms-543?q=qsfp
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              {" "}
              <Button
                variant="primary"
                onClick={() => navigate("/mms-543?q=qsfp")}
              >
                QSFP
              </Button>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title as="h3">Products per Category</Card.Title>
              <Card.Subtitle as="h4" className="muted">Endpoint #9: Parts per Category</Card.Subtitle>
              
              <Card.Text>A page listing all products for a given category</Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" onClick={() => navigate("/categories/search?q=splices")}>
                Splices Products
              </Button>
            </Card.Footer>
          </Card>
          </CardGroup>
      
      <CardGroup>
          <Card>
            <Card.Body>
              <Card.Title as="h3">XRef Search</Card.Title>

              <Card.Text>Cross reference search</Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" onClick={() => navigate("/xref")}>
               XRef
              </Button>
            </Card.Footer>
          </Card>
 
          <Card>
            <Card.Body>
              <Card.Title as="h3"> Product Details for compare</Card.Title>
              <Card.Subtitle as="h4" className="muted">
                {" "}
                Endpoint #8: Part Details for a List of Part Numbers{" "}
              </Card.Subtitle>
              <Card.Text>
                Supply a space separated list of part numbers e.g "q=1731070124 1727040110"
                API
                https://molex-dev.b.lucidworks.cloud/api/apps/molex_neo/query/product-details?q=1731070124 1727040110
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              {" "}
              <Button
                variant="primary"
                onClick={() => navigate("/products/1731070124 1727040110")}
              >
                Part #1731120600
              </Button>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title as="h3">All Parts</Card.Title>
              <Card.Subtitle as="h4" className="muted">
                {" "}
                Endpoint #7: All Parts
              </Card.Subtitle>
              <Card.Text>
                API:
                https://molex-dev.b.lucidworks.cloud/api/apps/molex_neo/query/part-list
              </Card.Text>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-between">
              <Button variant="primary" onClick={() => navigate("/products")}>
                All parts
              </Button>
              <Button
                variant="primary"
                onClick={() => navigate("/products/search?q=*")}
              >search for *</Button>
            </Card.Footer>
          </Card>
        
        </CardGroup>
        <CardGroup>
        <Card>
            <Card.Body>
              <Card.Title as="h3">Series List</Card.Title>
              <Card.Subtitle as="h4" className="muted">
                {" "}
                Endpoint #5: Series List, a list of all series
              </Card.Subtitle>
              <Card.Text>
                API
                https://molex-dev.b.lucidworks.cloud/api/apps/molex_neo/query/series-list
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              {" "}
              <Button
                variant="primary"
                onClick={() => navigate("/series/")}
              >
                A list of series
              </Button>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title as="h3">Parts per Series</Card.Title>
              <Card.Subtitle as="h4" className="muted">
                {" "}
                Endpoint #6: Returns an array of product details complete with response header information
                <pre>
                {` 
"responseHeader" : {
 "QTime" : 5,
 "totalTime" : 37,
 "params" : {
   "lw.pipelineId" : "parts-per-series",
   "fl" : "[_raw_content_, taxonomyPathValuesList, taxonomyPathIdsList]",
   "start" : "0",
   "isFusionQuery" : "true",
   "rows" : "20",
   "queryProfileID" : "parts-per-series",
   "fusionQueryId" : "BzVJSbDTkZ",
   "q" : "207841",
   "qf" : "[productSeries, materialMaster.productSeries.seriesId]"     
    }
  }`}
                </pre>
              </Card.Subtitle>
              <Card.Text>
                API
                https://molex-dev.b.lucidworks.cloud/api/apps/molex_neo/query/parts-per-series?q=207841
              </Card.Text>
            </Card.Body>
            <Card.Footer>
            </Card.Footer>
          </Card>
          
        
          </CardGroup>
      </Row>
      </Container>

    </>
  );
};

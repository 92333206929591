import Badge from "react-bootstrap/Badge";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { TrackSearch } from "./SignalGeneration";
import { Context } from "../App"
import { ReactComponent as Document } from './document.svg'

export const ProductResult = ({
  result,
  index = 1,
  urlField = "id",
  titleField = "materialMaster.productNumberDisplay",
  thumbnailField = "digitalAssets.isoImage",
  descriptionField = "general.webDescription",
  requestId = "1",
  resultSearchTerm = "",
  
}: {
  result: any[];
  index: number;
  urlField?: string;
  titleField?: string;
  requestId?: string;
  descriptionField?: string;
  resultSearchTerm?: string;
  thumbnailField?: string;

}) => {

  const ip = useContext(Context);
  //console.log(ip)
  //console.log(result)

  let clickData = {
    type: "click",
    params: {
      user_id: "search_svc",
      ctype: "result",
      query: resultSearchTerm,
      fusion_query_id: requestId,
      res_pos: index,
      doc_id: result["id"],
      url: `/products/${result[urlField]}`,
      ip_address:  ip,
      browser_name:  window.navigator.userAgent
    }
  };

  return (
    <div key={result["id"]} className="individualResult" role="article">
      {result[thumbnailField] && result[thumbnailField] !== "null" &&  result[thumbnailField] !== "" ? (
        <img src={result[thumbnailField]} alt="" className="thumb img-thumbnail" width={"180px"}/>
      ) : (
        <img
          src="https://via.placeholder.com/180.png?text=Product+image"
          alt="placeholder"
          className="thumb img-thumbnail"
        />
      )}

      <div className="w-100">
        <h4>
          {result[urlField] && result[titleField] ? (
            <Link
              to={`/products/${result[urlField]}`}
              onClick={() => {
                TrackSearch(clickData);
              }}
            >
              {result[titleField]}
            </Link>
          ) : result[urlField] ? (
            <Link
              to={`/products/${result[urlField]}`}
              onClick={() => {
                TrackSearch(clickData);
              }}
            >
              {result[urlField]}
            </Link>
          ) : (
            result["id"] + ": " + result[titleField]
          )}
        </h4>
  
        <p className="snippet">{result[descriptionField]} </p>
        <div className="d-flex justify-content-between ">
          <div className="flex-grow-1 details">
           <p> <b>Part Number:</b> {result["materialMaster.engineeringNumber"]}</p>
           <p> <b>Sales Status Code:</b> {result["materialMaster.salesStatusCode"]}</p>
           {result["engineeringDocs.salesDrawingPdf"] &&
           <p><Document /> <a href={`${result["engineeringDocs.salesDrawingPdf"]}`}  className="text-reset">Sales Drawing</a></p>}
           {result["engineeringDocs._3DCadModels"] &&
           <p> <b>3D Cad Models:</b> {result["engineeringDocs._3DCadModels"]}</p> }
           {result["engineeringDocs.productSpecificationPdf"] && 
           <p> <b>Product Specification Pdf:</b> {result["engineeringDocs.productSpecificationPdf"]}</p>
          }
          </div>
          <p className="large">
            <Badge
              bg={
                result["materialMaster.productStatus"] === "Active" ? "success" : "secondary"
              }
            >
              {result["materialMaster.productStatus"]}
            </Badge>
          </p>
        </div>
      </div>
    </div>
  );
};



import { Connection } from "../config/properties";
//import { Context } from "../App"
//import { useContext } from "react";
import axios from "axios";

export function TrackSearch(params) {

  //const ip = useContext(Context);


  params["timestamp"] = Date.now();
  params["id"] = crypto.randomUUID();
   const data = [params];
  const credentials = window.btoa(`${Connection.user}:${Connection.password}`);
  console.log(data)
  axios({
    method: "post",
    url: Connection.serverUrl + Connection.signalsEndpoint,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Basic ${credentials}`,
    },
    data: data,
  });
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import type {
  APIConnector,
  AutocompleteQueryConfig,
  AutocompleteResponseState,
  QueryConfig,
  RequestState,
  ResponseState,
} from "@elastic/search-ui";
import { TrackSearch } from "./SignalGeneration";
import { Connection, UIProperties } from "../config/properties";

interface FusionAPIConnectorParams {
  serverUrl: string;
  app: string;
  queryProfile: string;
  ip: string;
}

export class FusionAPIConnector implements APIConnector {
  serverUrl: string;
  app: string;
  queryProfile: string;
  ip: string;

  constructor({
    serverUrl,
    app,
    queryProfile,
    ip,
    ...rest
  }: FusionAPIConnectorParams) {
    if (!serverUrl) {
      throw Error("serverUrl is required");
    }
    this.serverUrl = serverUrl;
    this.app = app;
    this.queryProfile = queryProfile;
    this.ip = ip
  }


  async onSearch(
    state: RequestState,
    queryConfig: QueryConfig
  ): Promise<ResponseState> {
    const { searchTerm, current, filters, sort, resultsPerPage } = state;

    let selectedFilters = "";
    let analyticsFilers: string[] = [];
    const analyticsFacetList: string[] = [];
    let searchResponse: ResponseState = {
      requestId: "",
      facets: [],
      resultSearchTerm: "",
      totalPages: 0,
      totalResults: 0,
      pagingStart: 0,
      pagingEnd: 0,
      wasSearched: true,
      results: [],
      rawResponse: null,
    };
    const credentials = window.btoa(
      `${Connection.user}:${Connection.password}`
    );
    //fix for zero count offset
    let start = 0
    let rows = 10
    if (resultsPerPage) {
      rows = resultsPerPage
    }
    if (current && current > 1) {
      start = (current - 1) * rows
    }

    // console.log(filters);
    if (filters) {
      filters.forEach((f) => {
        // console.log(f);
        for (var i = 0; i < f.values.length; i++) {
          //check for productTaxonomy field
          selectedFilters += `&fq=${f.field}:("${f.values[i]}")`;
          analyticsFilers.push(f.field + "/" + f.values[i])
        }
      });
    }
    //productTaxonomy Products>Connectors>Terminal Blocks and Barrier Strip
    // perform a request to your API with the request state
    const response = await fetch(
      `${this.serverUrl}/api/apps/${this.app}/query/${this.queryProfile}?q=${searchTerm}&rows=${rows}${selectedFilters}&start=${start}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${credentials}`,
        },
      }
    );
    // response will need to be in the shape of ResponseState.
    // Alternatively you could transform the response here
    const data = await response.json();

    //console.log(data);



    var facetData: Record<string, any> = {};
    if (UIProperties.showFacets && (data?.content?.facet_counts || data?.product?.facet_counts || data?.facet_counts)) {
      if (Object.keys(data).includes("facet_counts")) {
        // we have a std result set
        //   console.log(Object.keys(data))
        if (UIProperties.showFacets && data.facet_counts?.facet_fields) {
          const facetList = Object.keys(data.facet_counts?.facet_fields);


          facetList.map((f) => {
            analyticsFacetList.push(f)
            const facetElement: { value: string; count: string }[] = [];
            const facet = [];
            for (let i = 0; i < data.facet_counts.facet_fields[f].length; i++) {
              facetElement.push({
                value: data.facet_counts.facet_fields[f][i][0],
                count: data.facet_counts.facet_fields[f][i][1],
              });
            }
            facet.push({ type: "value", data: facetElement });
            //     console.log(facetData)
            facetData[f] = facet;
            return null
          });
        }
      }
      else {
        if (data?.content?.facet_counts?.facet_fields) {
          const contentFacetList = Object.keys(data?.content?.facet_counts?.facet_fields);
          contentFacetList.map((f) => {
            analyticsFacetList.push(f)
            const facetElement: { value: string; count: string }[] = [];
            const facet = [];
            for (let i = 0; i < data.content.facet_counts.facet_fields[f].length; i++) {
              facetElement.push({
                value: data.content.facet_counts.facet_fields[f][i][0],
                count: data.content.facet_counts.facet_fields[f][i][1],
              });
            }
            facet.push({ type: "value", data: facetElement });
            //     console.log(facetData)
            facetData[f] = facet;
            return null
          });
          //console.log(facetData)
          if (data?.product?.facet_counts?.facet_fields) {
            const productFacetList = Object.keys(data?.product?.facet_counts?.facet_fields);
            productFacetList.map((f) => {
              //  console.log(f)
              analyticsFacetList.push(f)
              const facetElement: { value: string; count: string }[] = [];
              const facet = [];
              for (let i = 0; i < data.product.facet_counts.facet_fields[f].length; i++) {
               // console.log(f, data.product.facet_counts.facet_fields[f][i])
                facetElement.push({
                  value: data.product.facet_counts.facet_fields[f][i][0],
                  count: data.product.facet_counts.facet_fields[f][i][1],
                });
              }

              facet.push({ type: "value", data: facetElement });
              //  console.log(facet)
              facetData[f] = facet;
              //   console.log(facetData)
              return null
            });
          }
        }
      }
    }

    if (this.queryProfile === "main" || this.queryProfile === "mms-543" ) {
    //  console.log(Object.keys(data))
    
    if ((data.product && data.product.responseHeader)) {
     // console.log("we have product results")
      const partSearchResponse: ResponseState = {
        requestId: data.product.responseHeader.params.fusionQueryId || "",
        facets: facetData || "",
        resultSearchTerm: searchTerm || "",
        totalPages: data.product.response.numFound / data.product.responseHeader.params.rows,
        totalResults: data.product.response.numFound,
        pagingStart: +data.product.response.start + 1,
        pagingEnd: +data.product.responseHeader.params.rows + (+data.product.response.start),
        wasSearched: true,
        results: data.product.response.docs,
        rawResponse: data,
      };

      let analyticsMeta = {
        type: "request",
        params: {
          user_id: "search_svc",
          ctype: "request",
          ip_address: this.ip || "",
          query: searchTerm,
          fusion_query_id: data.product.responseHeader.params.fusionQueryId || "",
          filter: analyticsFilers,
          filter_field: analyticsFacetList || "",
        },
      };

      TrackSearch(analyticsMeta);
      return partSearchResponse;
    }

    else if (data.content  && data.content.responseHeader) {
     // console.log("we have content results but not product results")
      const contentSearchResponse: ResponseState = {
        requestId: data.content.responseHeader.params.fusionQueryId || "",
        facets: facetData || "",
        resultSearchTerm: searchTerm || "",
        totalPages: data.content.response.numFound / data.content.responseHeader.params.rows,
        totalResults: data.content.response.numFound,
        pagingStart: +data.content.response.start + 1,
        pagingEnd: +data.content.responseHeader.params.rows + (+data.content.response.start),
        wasSearched: true,
        results: data.content.response.docs,
        rawResponse: data,
      };

      let analyticsMeta = {
        type: "request",
        params: {
          user_id: "search_svc",
          ctype: "request",
          ip_address: this.ip || "",
          query: searchTerm,
          fusion_query_id: data.content.responseHeader.params.fusionQueryId || "",
          filter: analyticsFilers,
          filter_field: analyticsFacetList || "",
        },
      };

      TrackSearch(analyticsMeta);
      return contentSearchResponse;
    }
    else {
    //  console.log("empty results")
      const searchResponse: ResponseState = {
        requestId: "",
        facets: {},
        resultSearchTerm: searchTerm || "",
        totalPages: 0,
        totalResults: 0,
        pagingStart: 0,
        pagingEnd: 0,
        wasSearched: true,
        results: [],
        rawResponse: {data},
      };
      console.log(searchResponse.rawResponse)
      return searchResponse;
    }

    }
    else {
    //  console.log("we results in std format")
      const searchResponse: ResponseState = {
        requestId: data.responseHeader.params.fusionQueryId || "",
        facets: facetData || "",
        resultSearchTerm: searchTerm || "",
        totalPages: data.response.numFound / data.responseHeader.params.rows,
        totalResults: data.response.numFound,
        pagingStart: +data.response.start + 1,
        pagingEnd: +data.responseHeader.params.rows + (+data.response.start),
        wasSearched: true,
        results: data.response.docs,
        rawResponse: data,
      };
      let analyticsMeta = {
        type: "request",
        params: {
          user_id: "search_svc",
          ctype: "request",
          ip_address: this.ip||"",
          query: searchTerm,
          fusion_query_id: data.responseHeader.params.fusionQueryId || "",
          filter: analyticsFilers,
          filter_field: analyticsFacetList || "",
        },
      };

      TrackSearch(analyticsMeta);
      console.log(searchResponse)
      return searchResponse;
    }
  
  }

  async onAutocomplete(
    state: RequestState,
    queryConfig: AutocompleteQueryConfig
  ): Promise<AutocompleteResponseState> {
    const credentials = window.btoa(
      `${Connection.user}:${Connection.password}`
    );
    const { searchTerm } = state;
    //console.log(searchTerm)
    const response = await fetch(
      `${this.serverUrl}/api/apps/${this.app}/query/${Connection.suggestionsProfile}?q=${searchTerm}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${credentials}`,
        },
      }
    );
    const data = await response.json();

    // response will need to be in the shape of AutocompleteResponseState.
    // Alternatively you could transform the response here
    //console.log(data.grouped);
    const suggest: any = {}
    Object.keys(data.grouped.category.groups).forEach((group) => {
      //console.log(group)
     // console.log(data.grouped.category.groups[`${group}`].groupValue)
      const dictionary = data.grouped.category.groups[`${group}`].groupValue

      suggest[`${dictionary}`] = data.grouped.category.groups[`${group}`].doclist.docs.map((suggest: string) => {

        return { suggestion: suggest }

      })
      
    })
   // console.log(data.spellcheck)
   // console.log(data.spellcheck.collations.length)
    if (data.spellcheck.collations.length > 0) {


      const spellingCorrections: any[][] = data.spellcheck.collations.map((collation:any) => {
     // console.log(collation)
        if (collation !== "collation"){

          const suggestion = collation 
          // console.log(suggestion , typeof suggestion)
          return {suggestion:  {suggest: suggestion}}
        }
        else{
          return null
        }
      })
     // console.log(spellingCorrections)
     suggest["spelling"] = spellingCorrections.filter((spell: any) => spell !== null)
   //  console.log( suggest["spelling"] )






    }

    /*
    suggestions should be in the format
    documents[ {suggestion: 'inside'},{suggestion: 'inside trading'}, 
{suggestion: 'inside trading archives'}]
   products[ {suggestion: 'inside'},{suggestion: 'inside trading'}, 
{suggestion: 'inside trading archives'}]
   parts[ {suggestion: 'inside'},{suggestion: 'inside trading'}, 
{suggestion: 'inside trading archives'}]

    */

    //console.log(suggest);
    //console.log(suggest);
    const suggestResponse: AutocompleteResponseState = {
      autocompletedResults: [],
      autocompletedSuggestionsRequestId: "1",
      autocompletedResultsRequestId: "1",
      autocompletedSuggestions: suggest,
    };
    return suggestResponse;
  }

  onResultClick(params: any): void {
    console.log(
      "perform a call to the API to highlight a result has been clicked"
    );
  }

  onAutocompleteResultClick(params: any): void {
    console.log(
      "perform a call to the API to highlight an autocomplete result has been clicked"
    );
  }
}

import React, { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import {Tabs, Tab} from 'react-bootstrap';
import Spinner from "react-bootstrap/Spinner";
import { FusionAPIConnector } from "../components/FusionApiConnector";
import {
  ErrorBoundary,
  SearchProvider,
  PagingInfo,
  ResultsPerPage,
  Paging,
  WithSearch,
  Facet,
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { ResultTemplate } from "../components/ResultTemplate";
import { Header } from "../components/Header";
import { Context } from "../App"
import "../css/styles.css";
import "../css/index.css";
import { Connection } from "../config/properties";
import { formatCamelCase } from "../components/formatCamelCase";



export const Search = ({ profileType }) => {
  const ip = useContext(Context);
 // console.log(ip)
  const connector = new FusionAPIConnector({
    queryProfile: profileType,
    serverUrl: Connection.serverUrl,
    app: Connection.app,
    ip: ip
  });

  const config = {
    apiConnector: connector,
    debug: true,
    hasA11yNotifications: true,
    autocompleteSuggestions: true
  };

  function FacetList({facets}) {
    return Object.keys(facets.facet_fields).map((facet,index) =>
        <Facet
          key={index}
          className={"sui-facet__list"}
          field={facet}
          label={formatCamelCase(facet.substring(facet.lastIndexOf(".") === -1 ? 0 : facet.lastIndexOf(".") + 1, facet.lastIndexOf("_") === -1 ? facet.length : facet.lastIndexOf("_")))}
        />
      );
  }

  const [key, setKey] = useState("content");

  return (
    <SearchProvider config={config}>
      <WithSearch
        mapContextToProps={({
          wasSearched,
          results,
          resultSearchTerm,
          isLoading,
          requestId,
          rawResponse,
          facets
        }) => ({
          wasSearched,
          results,
          resultSearchTerm,
          isLoading,
          requestId,
          rawResponse,
          facets
        })}
      >
        {({
          wasSearched,
          results,
          resultSearchTerm,
          isLoading,
          requestId,
          rawResponse,
          facets
        }) => {

        

          return (
            <div className="App">
              <ErrorBoundary>
                <Container fluid className="App">
                  <Row className="header">
                    <Col>
                      <Header />
                    </Col>
                  </Row>
                  {isLoading ? (
                    <Row>
                      <Col>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "25vh" }}
                        >
                          <Spinner animation="border" variant="secondary" />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    profileType === 'main' || profileType === 'mms-543') ? (

                    <Row><Col><Tabs
                    id="main-content"
                    activeKey={key}
                    onSelect={(key) => setKey(key)}
                    
                    >
                      <Tab eventKey="content" title="Content"> <Row>
                      <Col>
                        <Layout
                          sideContent={
                            rawResponse.content  &&  rawResponse.content.facet_counts && (
                            <div>
                              <FacetList facets={rawResponse.content.facet_counts}/>
                            </div>
                    )}
                          bodyContent={
                            wasSearched &&  rawResponse.content  &&  rawResponse.content.response && (
                              <>
                              <ResultTemplate
                                key={rawResponse.content.response.docs.length}
                                pageType={"content"}
                                results={rawResponse.content.response.docs}
                                rawResults={rawResponse}
                                ip_address={ip}
                                requestId={requestId}
                                resultSearchTerm={resultSearchTerm}
                              />
                              </>
                            )
                          }
                          bodyHeader={
                            <React.Fragment>
                              {wasSearched && <PagingInfo />}
                              {wasSearched && <ResultsPerPage />}
                            </React.Fragment>
                          }
                          bodyFooter={<Paging />}
                        />
                      </Col>
                    </Row>  </Tab>
                      <Tab eventKey="product" title="Parts">  <Row>
                      <Col>
                        <Layout
                          sideContent={
                            rawResponse.product  &&  rawResponse.product.facet_counts && (
                            <div>
                              <FacetList facets={rawResponse.product.facet_counts}/>
                            </div>
                     ) }
                          bodyContent={
                            wasSearched && rawResponse.product  &&  rawResponse.product.response ? (
                              <>
                              <ResultTemplate
                                key={rawResponse.product.response.docs.length}
                                pageType={"product"}
                                results={rawResponse.product.response.docs}
                                rawResults={rawResponse}
                                ip_address={ip}
                                requestId={requestId}
                                resultSearchTerm={resultSearchTerm}
                              />
                              </>
                            ):(
                              <p>No parts results for {resultSearchTerm}</p>
                            )
                          }
                          bodyHeader={
                            <React.Fragment>
                              {wasSearched && <PagingInfo />}
                              {wasSearched && <ResultsPerPage />}
                            </React.Fragment>
                          }
                          bodyFooter={<Paging />}
                        />
                      </Col>
                    </Row> </Tab> 
                      </Tabs>
                      </Col></Row>
                  ) : (
                    <Row>
                      <Col>
                        <Layout
                          sideContent={
                            <div>
                              <FacetList type="product"/>
                            </div>
                          }
                          bodyContent={
                            wasSearched && (
                              <ResultTemplate
                                key={results.length}
                                pageType={profileType}
                                results={results}
                                rawResults={rawResponse}
                                ip_address={ip}
                                requestId={requestId}
                                resultSearchTerm={resultSearchTerm}
                              />
                            )
                          }
                          bodyHeader={
                            <React.Fragment>
                              {wasSearched && <PagingInfo />}
                              {wasSearched && <ResultsPerPage />}
                            </React.Fragment>
                          }
                          bodyFooter={<Paging />}
                        />
                      </Col>
                    </Row>
                  )}


                </Container>
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
};
